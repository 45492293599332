<template>
  <div v-if="$place"
       class="place-view">
    <div class="place-view__wrapper">
      <Section class="place-view__hero-billboard"
               limiter>
        <BreadCrumbs class="p-t-sm-20 p-t-md-20"
                     :params="{navigation, color: 'rgba(255,255,255,.5)'}"/>
        <h1 class="place-view__full-info-title"
            v-if="$mobile">{{$place.title}}</h1>
        <Loader v-if="$place.images.length === 0"/>
        <Slider :height="height"
                :indentation="indentation"
                :items="$place.images"
                class="place-view__slider"
                component="ImageComponent"
                :count="1"
                :limiter="$laptop"
                v-else/>
        <component :is="!$mobile ? 'Row' : 'Column'"
                   justify="between">
          <div class="grid-md-4 grid-lg-4">
            <List class="place-view__additions"
                  v-if="this.$refs.it"
                  :style="style">
              <template slot="list-content">
                <Column class="place-view__short-info">
                  <div class="place-view__short-info-row">
                    <p class="place-view__short-info-title">{{ $t('places_view.address') }}</p>
                    <p class="place-view__short-info-text">{{$place.address}}</p>
                  </div>
                  <div v-for="item in $place.additions"
                       :key="item.id"
                       class="place-view__short-info-row">
                    <p class="place-view__short-info-title">{{item.key}}</p>
                    <p v-if="getHostName(item.value)"
                       class="place-view__short-info-text">
                      <a :href="item.value">{{getHostName(item.value)}}</a>
                    </p>
                    <p v-else
                       class="place-view__short-info-text">{{item.value}}</p>
                  </div>
                </Column>
              </template>
            </List>
          </div>
          <div class="grid-md-4 grid-lg-8"
               :ref="'it'">
            <h1 class="place-view__full-info-title"
                v-if="!$mobile">{{$place.title}}</h1>
            <Row class="trip-view__badge -m-h-5"
                 wrap>
              <Badge v-for="badge in $place.tags"
                     :key="`badge-place-view-${badge.id}`"
                     :params="{text: badge.name, colors: {
                       text: '#ffffff', background: badge.color}}"/>
            </Row>
            <Column>
              <Row align="center"
                   class="place-view__rating-row">
                <Rating class="guest-review__rating"
                        :params="{id: $place.id,
                        type: 'places',
                        rating: $place.rating,
                        currentUserRating: $place.currentUserRating}"/>
              </Row>
              <span class="rating-count">{{$place.countRating}} {{plural($place.countRating,
                [`${$t('places_view.tourists.p_1')}`,
                `${$t('places_view.tourists.p_2')}`, `${$t('places_view.tourists.p_3')}`])}}
                {{ $t('places_view.this_obj') }}</span>
            </Column>
          </div>
        </component>
        <div class="place-view__play-button"
             v-if="$place && $place.audios && $place.audios.length"
             v-on:click="openPopupAudios">
          <Icon class="place-view__play-button-icon"
                xlink="play"
                viewport="0 0 20 20"/>
        </div>
      </Section>
      <Section limiter>
        <component :is="$laptop ? 'Row' : 'Column'"
                   justify="between">
          <Column class="grid-md-3 grid-lg-3">
            <h2 class="place-view__visit-title">{{ $t('places_view.about_obj') }}</h2>
          </Column>
          <Column class="grid-md-8 grid-lg-8 html-insert"
                  v-html="$place.description">
          </Column>
        </component>
      </Section>
      <Section class="trip-view__on-map"
               :limiter="!$mobile">
        <div class="trip-view__wrapper"
             :style="{width:'100%', height:'400px'}">
          <OnMap :location="$place && $place.location"
                 :params="{sizeMap: {width: '100%', height: '400px'}}"
                 :places="[$place]"/>
        </div>
      </Section>
      <Section limiter>
        <Row justify="between"
             v-if="$place && $place.youtube">
          <Column class="grid-md-3 grid-lg-3"/>
          <Column class="place-view__visit-text grid-md-8 grid-lg-8">
            <VideoYouTube v-if="$place && $place.youtube"
                          class="place-view__video"
                          :params="{width: $laptop ? '730px'
                          : '100%', height: $laptop ? '400px'
                          : '100%', src: $place.youtube}"/>
          </Column>
        </Row>
      </Section>
      <Section v-if="$showIfDefaultCity"
               :limiter="!$mobile">
        <FormationPortal :backgroundPosition="!$mobile ? 'center right' : 'right'"
                         :backgroundImage="!$mobile ?
                         require('@/assets/images/place-on-map.png') : ''">
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('places_view.know_obj') }}</span>
          </template>
          <template slot="formation-portal-description">
            <span class="formation-portal__description">{{ $t('places_view.reg_add') }}</span>
          </template>
          <Button color="transparent"
                  v-on:click.native="$openPopupAddObject">{{ $t('places_view.add_obj') }}
          </Button>
        </FormationPortal>
      </Section>
      <Section limiter>
        <GuestReviews :params="{title: `${$t('comments.reviews')}`,
        button: `${$t('comments.leave_rev')}`}"
                      typePage="places"/>
      </Section>
      <Section v-if="anotherPlacesInType.length > 1">
        <ThematicSlider :params="{array: anotherPlacesInType,
                                  component: 'PlaceCard',
                                  count}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('places_view.similar_obj') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
                 class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                           :to="`${$locale.base}/news`">{{$laptop ? $t('home.watch_all.p_1') : ''}}
                {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                    viewport="0 0 20 20"
                    :rotate="90"
                    xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
  <Loader :params="{style: {
                    width: '100%',
                    maxWidth: '1110px',
                    height: '50vh',
                    margin: '80px auto'}}"
          v-else/>
</template>

<script>

export default {
  name: 'PlaceView',
  data() {
    return {
      anotherPlacesInType: [],
      style: {
        maxHeight: '350px',
      },
    };
  },
  created() {
    this.$store.dispatch('GET_PLACE_FROM_SERVER_WITH_PARAMS', this.$route.params.id);
    this.$store.dispatch('GET_NEAR_PLACES_FROM_SERVER', this.$route.params.id);
  },
  updated() {
    if (this.$refs.it && !this.$mobile) {
      this.style.maxHeight = `${this.$refs.it.offsetHeight}px`;
    }
  },
  methods: {
    getHostName(url) {
      const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      }
      return null;
    },
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    openPopupAudios() {
      this.$store.commit('OPEN_POPUP', {
        clear: true,
        width: this.$laptop ? 800 : '100%',
        height: this.$laptop ? '100vh' : '100vh',
        horizontal: 'right',
        vertical: 'center',
        type: 'PopupAudioGuide',
      });
    },
  },
  computed: {
    height() {
      let height;
      if (this.$laptop) height = 405;
      if (this.$tablet) height = 405;
      if (this.$mobile) height = 180;
      return height;
    },
    indentation() {
      let indentation;
      if (this.$laptop) indentation = 30;
      if (this.$tablet) indentation = 30;
      if (this.$mobile) indentation = 8;
      return indentation;
    },
    count() {
      let count;
      if (this.$laptop) count = 3;
      if (this.$tablet) count = 2;
      if (this.$mobile) count = 1;
      return count;
    },
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/places',
        title: this.$i18n.t('menu.objects'),
      }, {
        href: this.$route.path,
        title: this.$place.title,
      }];
    },
  },
  watch: {
    $place() {
      this.$http.get(`/api/places?type_id=${this.$place.type.id}&count=100500&lang=${this.$i18n.locale}`)
        .then((resolve) => {
          this.anotherPlacesInType = resolve.data.data.filter((item) => item.id !== this.$place.id);
        });
      this.$store.commit('SET_PLAYLIST', this.$place.audios);
    },
    $route(newState) {
      this.$store.dispatch('GET_PLACE_FROM_SERVER_WITH_PARAMS', newState.params.id);
    },
  },
};
</script>
